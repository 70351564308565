body,
html {
  visibility: hidden;
  background: #003057;
  opacity: 1;
}

#rsvp {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.wf-active body,
.wf-active html {
  height: 100%;
  margin: 0;
  font: 400 16px/1.8 "Asap", sans-serif;
  visibility: visible;

  background: #ffffff;

  -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2.5s; /* Firefox < 16 */
  -ms-animation: fadein 2.5s; /* Internet Explorer */
  -o-animation: fadein 2.5s; /* Opera < 12.1 */
  animation: fadein 2.5s;
}

.title {
  text-align: center;
}

#banner {
  padding-top: 0.7vw;
  opacity: 0.65;
  text-align: center;
  width: 100%;
  height: 14vw;
  background: #003057;
  /* top: 450px; */
  top: 32vw;
  position: absolute;
}

.banner-come {
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 2.08vw;
  line-height: 4.2vw;
  text-align: center;

  color: #ffffff;
}
.banner-date {
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 4.2vw;
  line-height: 4.2vw;
  text-align: center;

  color: #ffffff;
}

#banner-location {
  font-family: Asap;
  font-style: normal;
  font-weight: normal;
  font-size: 2.08vw;
  line-height: 5.56vw;

  /* identical to box height */
  text-align: center;

  color: #ffffff;
}

#us-img img {
  width: 100%;
}

#map {
  height: 600px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}

.bgimg-1,
.bgimg-2,
.bgimg-3,
.bgimg-4,
.bgimg-empty {
  position: relative;
  opacity: 0.65;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bgimg-1 {
  background-image: url("images/TCbay1.jpg");
  min-height: 400px;
}

.bgimg-2 {
  background-image: url("images/Peninsula_Room_Logo_Trees.JPG");
  min-height: 600px;
}

.bgimg-3 {
  background-image: url("images/frontstreet700.jpg");
  min-height: 400px;
}

.bgimg-4 {
  background-image: url("images/winners/IMG_4995.JPG");
  min-height: 400px;
}

.bgimg-empty {
  min-height: 100px;
}

.caption {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  text-align: center;
  color: #000;
}

.caption span.border {
  background-color: #003057;
  color: #fff;
  padding: 18px;
  font-size: 28px;
  letter-spacing: 10px;
}

.modal-content img {
  max-height: 90vh;
  max-width: 90vw;
  object-fit: contain;
}

.icon {
  font-size: 40px;
  opacity: 0.55;
}

.icon:hover {
  font-size: 40px;
  opacity: 1;
  cursor: pointer;
}

#image-inline img {
  cursor: pointer;
  max-height: 75vh;
  object-fit: contain;
}

.inline-carousel-container {
  display: flex;
  justify-content: center;
}

.modal-content .carousel-buttons {
  position: absolute;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
  padding-top: -30px;
  width: 100%;
}

.navbar {
  opacity: 0.65;
}

.navbar-start {
  margin-left: auto;
  margin-right: unset;
}

.navbar .navbar-item {
  cursor: pointer;
}
/* Links inside the navbar */
/*
.navbar a {
  float: right;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}
*/

/* Change background on mouse-over */
/*
.navbar a:hover {
  background: #ddd;
  color: black;
}
*/

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Turn off parallax scrolling for tablets and phones */
@media only screen and (max-device-width: 1024px) {
  .bgimg-1,
  .bgimg-2,
  .bgimg-3,
  .bgimg-4 {
    background-attachment: scroll;
  }

  .navbar {
    opacity: 1;
  }

  #welcome-welcome {
    position: relative;
    top: 3.25rem;
    padding-bottom: 2rem;
  }

  #banner {
    height: 22vw;
    background: #003057;
    top: 26vw;
    position: absolute;
  }

  .banner-come {
    font-size: 3vw;
    line-height: 6vw;
  }
  .banner-date {
    font-size: 8vw;
    line-height: 8vw;
  }

  #banner-location {
    font-size: 3vw;
    line-height: 8vw;
  }
}
